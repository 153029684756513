import React, {useState, useEffect, useRef} from 'react'
import PropTypes from "prop-types"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipeable } from 'react-swipeable';
import Text from './carouselText'
const TabbedCarousel3d = (props) => {

  useEffect(() => {
    setActive(1);
  },[]);

  const [active, setActive] = useState(1);
  const totalChildren = props.children.length;
  const tabMenu = useRef();

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      handleActive(e,{direction:'right'});
    },
    onSwipedRight: (e) => {
      handleActive(e,{direction:'left'});
    }
  });

  const scrollTabs =(e)=>{
    let childKey = e - 1;
    tabMenu.current.scroll({
      top: 0,
      left: tabMenu.current.children[childKey].offsetLeft - 20,
      behavior: 'smooth'
    });
  }

  const handleActive = (e,options=undefined) => {
    if(options){
      if(options.key){
        setActive(parseInt(options.key));
      }
      if(options.direction){
        switch(options.direction) {
          case "right":
            if(parseInt(active) === totalChildren){
              setActive(1);
                scrollTabs(1);
            }else{
              setActive(parseInt(active) + 1);
                scrollTabs(parseInt(active) + 1);
            }
          break;
          case "left":
            if(parseInt(active) === 1){
              setActive(parseInt(totalChildren));
                scrollTabs(totalChildren);
            }else{
              setActive(parseInt(active) - 1);
                scrollTabs(parseInt(active) - 1);
            }
          break;
          default:
            return false;
        }
      }
    }else{
      return false;
    }
  }

  return (
    <div className={`tabbed-carousel row row--inner align-center ` + props.className}>
      <div className="columns large-11">
        <div className="row">
          <div className="columns">
            <ul className="tabbed-carousel__tabs" ref={tabMenu}>
              {React.Children.map(props.children, child => {
                return (
                  <li className={active === parseInt(child.key)? 'isActive' : ''}><button onClick={(e) => handleActive(e, {key: child.key})}>{child.props.name}</button></li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="row tabbed-carousel__content align-center" {...handlers}>
          <div className="columns tabbed-carousel__slide-container">
            <button onClick={(e) => handleActive(e, {direction: 'left'})} className="tabbed-carousel__button"><FontAwesomeIcon icon={faAngleLeft} /><span className="visually-hidden">Previous Slide</span></button>
            <ul className="tabbed-carousel__slides">
            {React.Children.map(props.children, child => {
              return (
                <li className={active === parseInt(child.key)? 'isActive' : ''}>
                    <div>
                      {child.props.children[0]}
                    </div>
                </li>
              )
            })}
            </ul>
            <button onClick={(e) => handleActive(e, {direction: 'right'})} className="tabbed-carousel__button tabbed-carousel__button--next"><FontAwesomeIcon icon={faAngleRight} /><span className="visually-hidden">Next Slide</span></button>
          </div>
        </div>
        <div className="row tabbel-carousel__text align-center mt-1">
          <div className="columns large-9 small-10">
            {React.Children.map(props.children, child => {
              const text = React.cloneElement(child.props.children[1]);
              return (
                <div>
                  {active === parseInt(child.key) && text }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}


TabbedCarousel3d.Text = Text
TabbedCarousel3d.Pane = ({ children }) => children

TabbedCarousel3d.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabbedCarousel3d
