import React from "react"
import PropTypes from "prop-types"
import videoScreen from "../../../images/tvicon_yellow.png";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideoInfoCard = (props) => {
  return (
    <div className={`row row--inner align-middle align-center small-col-reverse ` + props.className}>
      <div className="columns small-10 large-8 lext-left">
        <a data-gtm-event-category={props.gtmCat} data-gtm-event-action={props.gtmAction} data-gtm-event-label={props.gtmLabel} href={props.link} target="_blank" rel="noreferrer" className="h5 jost-bold tc-barney underline">{props.text} <small><FontAwesomeIcon icon={faExternalLinkAlt} /></small></a>
      </div>
      <div className="columns small-6 large-2 text-center">
        <img src={props.img} alt={props.text}/>
      </div>
    </div>
  )
}

VideoInfoCard.defaultProps = {
  text: 'Watch a neuroblastoma doctor talk about the basics of neuroblastoma at a recent parent conference',
  img: videoScreen,
  link: 'http://www.google.com',
  gtmCat: 'GTM Video Card Not Defined',
  gtmAction: 'GTM Action Not Defined',
  gtmLabel: 'GTM Label Not Defined'
};

VideoInfoCard.propTypes = {
  text: PropTypes.node.isRequired,
  img: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired,
  gtmCat: PropTypes.node.isRequired,
  gtmAction: PropTypes.node.isRequired,
  gtmLabel: PropTypes.node.isRequired
}

export default VideoInfoCard
