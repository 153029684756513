import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import NewlyDiagnosedSubmenu from "../../components/template-partials/newly-diagnosed/newly-diagnosed-submenu"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"

import { UpNextCard } from "../../components/template-partials/global-components/footer-cards"

import videoScreen from "../../images/tv-blue.png"
import headingBg from "../../images/2.0-headerbg.jpg"
import headingRound from "../../images/newly-diagnosed-nav-image.jpg"
import siblings from "../../images/2.1-siblings.jpg"
import keepingRelationships from "../../images/keepingrelationshipopen.jpg"
import talkingWithothers from "../../images/talkingwithotherparents.jpg"
import mentalHealth from "../../images/mentalhealth.jpg"
import spritual from "../../images/spiritual.jpg"
import askForHelp from "../../images/askforhelp.jpg"
import writeInJournal from "../../images/writeinjournal.jpg"
import supportGroup from "../../images/supportgroup.jpg"
import feelings from "../../images/diagnosis-feelings.jpg"

import thirteenEighteeen from "../../images/13-to-18-years-old.png"
import sixTwelve from "../../images/6to12yearsold.jpg"
import twoFive from "../../images/2-to-5-years-old.jpg"
import infantTwo from "../../images/infant-to-2-years-old.jpg"

import skivolo from "../../images/Skivolo.png"

import { faAngleRight, faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import thumbResource from "../../images/thumb-coping-with-childhood.png"

import TabbedCarousel3d from "../../components/template-partials/global-components/tabbed-carousel/tabbedCarousel3d"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const CopingWithDiagnosisPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: "unslick" },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Layout>
      <SEO
        title="Coping with a Neuroblastoma Diagnosis"
        description="No parent is prepared to hear that their child has been diagnosed with neuroblastoma and coping with the subsequent emotions can be difficult. Discover helpful tips for navigating both your child’s diagnosis and your family’s feelings."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Coping With a Neuroblastoma Diagnosis"
      >
        <h1 className="section__heading section__heading--green h1">
          Coping <em>With</em> &nbsp;a Neuroblastoma Diagnosis
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Newly Diagnosed`}>
              <NewlyDiagnosedSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">
                  Starting your journey as a neuroblastoma caregiver{" "}
                </h2>
                <p>
                  Learning your child has been diagnosed with neuroblastoma can
                  be devastating. It is okay to be concerned for your child and
                  to have questions about treatment. As you start your journey
                  as a caregiver, remember that you are not alone.
                </p>
                <p>
                  In this section, you will find information to help you cope
                  with the diagnosis and prepare for the neuroblastoma journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns small-12 large-4 text-center">
            <FancyImage
              url={feelings}
              alt="Your child’s diagnosis and your feelings"
            />
          </div>
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Your child’s diagnosis and your feelings{" "}
            </h3>
            <p>
              No parent or caregiver is ever prepared for the words, “Your child
              has cancer.” This news can cause feelings of fear, anger, sadness,
              or guilt. Managing your emotions can be a challenge during the
              first few weeks–this is normal.
            </p>
            <p>
              Learning unfamiliar medical terms and making treatment decisions
              while also caring for your child and family may feel overwhelming.
              Be patient with yourself and your family as you adapt. Here you
              will find tips to help you and your family cope after diagnosis.
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Coping with a neuroblastoma diagnosis{" "}
            </h3>
            <p>
              Learning how to cope with stress and build a support system can
              give you the strength and courage you need for the journey ahead.
              Some coping techniques that may help are:
            </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-3">
          <div className="columns">
            <Slider className="grid-slider grid-slider--large-4" {...settings}>
              <div className="text-center">
                <img
                  src={keepingRelationships}
                  alt="Staying connected with friends can help you cope with a neuroblastoma diagnosis"
                />
                <p className="mt-2">Stay connected with friends</p>
              </div>
              <div className="text-center">
                <img
                  src={talkingWithothers}
                  alt="Cope with a neuroblastoma diagnosis by talking to other parents of children with cancer"
                />
                <p className="mt-2">
                  Talk to other parents of children with cancer
                </p>
              </div>
              <div className="text-center">
                <img
                  src={writeInJournal}
                  alt="Keeping a journal can help you cope with your child’s neuroblastoma diagnosis"
                />
                <p className="mt-2">Keep a journal</p>
              </div>
              <div className="text-center">
                <img
                  src={askForHelp}
                  alt="Ask for help from your support system"
                />
                <p className="mt-2">Ask for help</p>
              </div>
              <div className="text-center">
                <img
                  src={mentalHealth}
                  alt="Speak to a counselor or therapist about your child’s neuroblastoma diagnosis"
                />
                <p className="mt-2">Talk to a counselor or therapist</p>
              </div>
              <div className="text-center">
                <img
                  src={supportGroup}
                  alt="Build a support system with a neuroblastoma support group"
                />
                <p className="mt-2">
                  <Link to="/support-and-community/support-groups/">
                    Connect with a neuroblastoma support group{" "}
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </p>
              </div>
              <div className="text-center">
                <img
                  src={spritual}
                  alt="Rely on spiritual practices to help you cope with your child’s neuroblastoma diagnosis."
                />
                <p className="mt-2">Rely on spiritual practices</p>
              </div>
            </Slider>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Helping your child through the diagnosis{" "}
            </h3>
            <p>
              Regardless of your child’s age at diagnosis, it is important to
              provide them with a sense of security and reassurance. Most
              children will feel less worried and scared if they are included in
              their care plan, and may benefit from receiving honest,
              age-appropriate information. Your child’s healthcare team can help
              with this.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-3">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              Select the age of your child to learn ways to provide comfort.
            </h4>
          </div>
        </div>
        <div className="row row--inner align-center mb-2 hide-for-large">
          <div className="columns shrink">
            <p>
              <small>← swipe left to right to see all topics →</small>
            </p>
          </div>
        </div>
        <TabbedCarousel3d className="lg_mt-2">
          <TabbedCarousel3d.Pane name="Infants up to 2 years old" key="1">
            <img src={infantTwo} width="100%" height="auto" alt="infant2" />
            <TabbedCarousel3d.Text>
              <p>
                Infants and children under 2 years of age can be comforted by
                the sound of your voice, your touch, and by having familiar
                items like a favorite blanket nearby. Keeping the routine they
                had at home may help them feel more secure. Fear of strangers,
                like the healthcare team at first, is common.
              </p>
            </TabbedCarousel3d.Text>
          </TabbedCarousel3d.Pane>
          <TabbedCarousel3d.Pane name="2 to 5 years old" key="2">
            <img src={twoFive} width="100%" height="auto" alt="infant five" />
            <TabbedCarousel3d.Text>
              <p>
                Children 2 to 5 years of age may enjoy playing with special toys
                designed to teach children about their treatment. They can learn
                basic information about cancer and how their routine may change.
                Often, they need reassurance that their cancer is not a
                punishment for something they did. Acting out or regression in
                behaviors like thumb-sucking and bed-wetting is common.
              </p>
            </TabbedCarousel3d.Text>
          </TabbedCarousel3d.Pane>
          <TabbedCarousel3d.Pane name="6 to 12 years old" key="3">
            <img src={sixTwelve} width="100%" height="auto" alt="infant 6" />
            <TabbedCarousel3d.Text>
              <p>
                Children 6 to 12 years of age can play a bigger role in their
                care. They should be told about their cancer and how it will be
                treated, and should be encouraged to ask questions. They may
                have concerns about school and changes in their appearance, and
                may also show anger and sadness over the loss of normal life.
              </p>
            </TabbedCarousel3d.Text>
          </TabbedCarousel3d.Pane>
          <TabbedCarousel3d.Pane name="13 to 18 years old" key="4">
            <img
              src={thirteenEighteeen}
              width="100%"
              height="auto"
              alt="infant seven"
            />
            <TabbedCarousel3d.Text>
              <p>
                Children 13 to 18 years of age can usually understand more
                complex information regarding their cancer and treatment.
                Changes in appearance, such as weight changes and hair loss, may
                be a concern. The healthcare team should include them in
                discussions and education, whenever possible.
              </p>
            </TabbedCarousel3d.Text>
          </TabbedCarousel3d.Pane>
        </TabbedCarousel3d>
      </Section>

      <Section id="siblings">
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Helping siblings cope with the diagnosis{" "}
            </h3>
            <p>
              Siblings experience their own emotional responses. Continuing to
              make room for their involvement and communicating with them helps
              them feel like a valued member of the family.
            </p>
            <p>
              Older siblings may feel changes happening around them at home.
              This can make them sad or anxious knowing their brother or sister
              is sick. They may get upset or act out more often because of the
              uncertainty surrounding them. You can help them by:
            </p>
            <ul className="ul--dotted">
              <li>Reminding them that they are loved and valued by you</li>
              <li>
                Spending alone time with them while doing something they enjoy{" "}
              </li>
              <li>
                Reminding them that they are not to blame. Nothing they did,
                thought, or said caused their brother or sister to get cancer
              </li>
              <li>
                Talking with your child’s healthcare team about providing
                education and support services for siblings{" "}
              </li>
            </ul>
            <p className="show-for-large"><Link to="/support-and-community/mental-health/#impact-on-siblings">Watch a discussion between a childhood cancer survivor and an expert mental health clinician on the impact childhood cancer can have on sibling</Link></p>
          </div>
          <div className="columns small-12 large-4 text-center">
            <FancyImage url={siblings} alt="Hello Lovely" />
          </div>
          <div className="columns small-12 hide-for-large">
            <p><Link to="/support-and-community/mental-health/#impact-on-siblings">Watch a discussion between a childhood cancer survivor and an expert mental health clinician on the impact childhood cancer can have on sibling</Link></p>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <div
              className="card"
              style={{
                backgroundColor: SassVars.viridian,
                borderColor: SassVars.turquoise,
              }}
            >
              <div className="row align-bottom">
                <div className="columns large-9 small-12 text-left">
                  <p className="tc-white no-pad h5">
                    To help your child or their siblings cope with diagnosis or
                    treatment, they may enjoy getting to know Skivolo. Skivolo
                    is a young red panda with high-risk neuroblastoma.{" "}
                    <Link
                      to="/support-and-community/skivolos-kid-zone/"
                      className="tc-white"
                    >
                      Go to Skivolo’s Kid Zone, where you can find activities
                      and storybooks to educate your child and their siblings on
                      neuroblastoma.
                    </Link>{" "}
                    You can also ask your care team to help you order different
                    Skivolo resources.
                  </p>
                </div>
                <div className="columns text-center small-12 large-3 mt-2">
                  <img
                    className="card__image-flush-bottom"
                    src={skivolo}
                    alt="skivolo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Talking to other family members or friends
            </h3>
            <p>
              A new diagnosis of neuroblastoma may be overwhelming, but you are
              not alone. You probably have many family members and friends who
              are willing to help. In the early days, they may have a lot of
              questions. Discussing your child’s neuroblastoma with your loved
              ones may be difficult in the beginning, but you can set boundaries
              and decide which details to share. Sometimes it is helpful to
              designate someone you trust to be your “spokesperson” to update
              family and friends so you can focus your energy on other important
              things.
            </p>
            <p>
              <strong>
                As you begin to settle in with your emotions, you may start
                thinking ahead to treatment for your child.{" "}
              </strong>
            </p>
            {/* <VideoInfoCard
              gtmCat="How is Neuroblastoma Diagnosed"
              gtmAction="Click"
              gtmLabel="Video Neuroblastoma Diagnosis"
              className="lg_mt-4"
              link={`https://www.youtube.com/watch?v=bI6iRHusDM4`}
              text="Watch a behavioral medicine doctor talk about coping with a childhood cancer diagnosis at a recent parent conference."
              img={videoScreen}
            /> */}
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Coping with a Neuroblastoma Diagnosis
            </h3>
            <p>
              Learn from a clinical social worker about helping your child and
              family cope with the diagnosis.
            </p>
            <VideoPlayer
              embedId="761928446"
              title="Coping with a Neuroblastoma Diagnosis"
            />
          </div>
        </div>
      </Section>

      <Section id="resources-guide" style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner">
          <div className="columns small-12 mb-2 md_mb-2 lg_mb-2">
            <h3 className="h2 tc-barney jost-med">
              Resources
            </h3>
          </div>
          <div className="columns small-12">
            <div className="row row--inner">
              <div className="columns small-12 large-4 large-text-left text-center">
                <img
                  src={thumbResource}
                  alt="Resources"
                  className="small-8 large-11"
                />
              </div>
              <div className="columns mt-2">
                <h4 className="tc-viridian jost-med">Coping with Childhood Cancer Guide </h4>
                <p>A step-by-step guide to help set a good foundation for you and your child's mental health throughout your journey. This guide takes you from initial diagnosis to after treatment, with considerations to help you cope with childhood cancer and advocate for resources that may be available to you. Use this guide as you navigate the childhood cancer journey.</p>
                <a
                    href="/Mental_Health_Companion_Piece.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Coping with Childhood Cancer Guide"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Coping with Childhood Cancer Guide <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <p className="mt-1 md_mt-1 lg_mt-1"><a href="/support-and-community/order-resources/">or request a printed version here</a></p>
              </div>
            </div>
            <div className="columns small-12 mt-2 md_mt-2 lg_mt-2">
               <p><a href="/support-and-community/downloadable-resources/">To receive additional resources to help you and your family cope with a childhood cancer diagnosis, click here. </a></p>
            </div>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Understanding the Treatment Options"
          title="What treatment might my child receive?"
          lead="Get an overview of neuroblastoma treatments by risk group."
          url="/diagnosis/understanding-treatment/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default CopingWithDiagnosisPage
