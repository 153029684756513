import React from "react"
import PropTypes from "prop-types"

const VideoPlayer = ({
  className,
  embedId,
  title,
  data,
  isShowcase = false,
  youtube = false,
  isCarousel = false,
  style,
}) => {
  var srcLink = "https://player.vimeo.com/video/" + embedId
  if (youtube) {
    srcLink = "https://www.youtube-nocookie.com/embed/" + embedId
  }

  return (
    <div
      className={isCarousel ? "" : "video-player " + className}
      style={style}
    >
      {!youtube ? (
        <>
          <iframe
            id="video-player"
            src={srcLink + `?` + data}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            width="100%"
            height="100%"
            title={title}
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </>
      ) : (
        <iframe
          width="100%"
          height="100%"
          src={srcLink + embedId + "&enablejsapi=1"}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default VideoPlayer
