import React from 'react'

const Drawer = ({ children}) => (
  <div>
    {children}
  </div>
)


export default Drawer
